import React, { useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import chevron from '../assets/images/chevron-white.svg';

const TestimonialSlider = ({ children }) => {
  const childrenCount = React.Children.count(children)
  const slider = useRef(null);

  return (
    <div className="testimonials">
      <div className="slides">
        <Slider
          arrows
          speed={1000}
          slidesToShow={1}
          ref={slider}
          autoplay
          autoplaySpeed={3500}
        >
          { children }
        </Slider>
      </div>
      {
        childrenCount > 1 && (
          <div className="slider-toggle">
            <img
              role="button"
              tabIndex={0}
              className="previous"
              src={chevron}
              alt=""
              onClick={() => {
                slider.current.slickPrev();
              }}
            />
            <div className="line"></div>
            <img
              role="button"
              tabIndex={0}
              className="next"
              src={chevron}
              alt=""
              onClick={() => {
                slider.current.slickNext();
              }}
            />
          </div>
        )
      }
    </div>
  );
};

TestimonialSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TestimonialSlider;
