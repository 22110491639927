import React from 'react';

import icon from '../assets/images/mdi-quote.svg';

const Quote = ({
  author, position, avatar, content, logo, classes
}) => (
  <div className={`quote-container ${classes || ""}`}>
    <img className="icon" src={icon} alt="" />
    <div className="text">
      {content}
    </div>
    <div />
    <div className="base-chip -large author">
      <img className="avatar" src={avatar} alt="" />
      <div className="text">
        <div className="title">
          <span className="name">{author}</span>
          <span className="position">{position}</span>
        </div>
        <img className="logo" src={logo} alt="" />
      </div>
    </div>
  </div>
);

export default Quote;
