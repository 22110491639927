import React from 'react';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import Card from '../../components/Card';
import Layout from '../../components/Layout';
import TestimonialSlider from '../../components/TestimonialSlider';
import Quote from '../../components/Quote';

import lukeEvans from '../../assets/images/luke.jpeg';
import helgaButcher from '../../assets/images/helga-butcher.jpg';
import twitter from '../../assets/images/bx_bxl-twitter.svg';
import facebook from '../../assets/images/bx_bxl-facebook.svg';
import linkedin from '../../assets/images/bx_bxl-linkedin.svg';
import buildings from '../../assets/images/buildings.jpg';
import helgaButcherArticle from '../../assets/images/helga-butcher-article.jpg';
import fibShot from '../../assets/images/fib-shot-3.jpg';
import ventures from '../../assets/images/venturesLogo.svg';
import fibonacci from '../../assets/images/fibonacci-full.svg';

export default ({ path }) => (
  <Layout
    pageName="default-article"
    path={path}
    metatitle="Fibonacci: the Golden Standard for Legal Project Management"
    image="/metaimages/homepage.jpg"
    description="Fibonacci is a built-for-purpose legal project management platform that has been designed and developed in partnership with a panel of innovative law firms..."
  >
    <div className="page">
      <div className="banner -resources">
        <div className="chip">NEWS</div>
        <h1>
          Fibonacci: the Golden Standard for
          <br />
          Legal Project Management
        </h1>
      </div>
      <div className="article-container">
        <img src={fibShot} className="image" alt="" />
        <div className="content">
          <p className="paragraph">
            Fibonacci is a built-for-purpose legal project management platform
            that has been designed and developed in partnership with a panel of
            innovative law firms and in-house corporate legal teams.
          </p>
          <p className="paragraph">
            Lawyers and legal professionals implement a range of process
            improvements and technologies in order to more efficiently deliver
            legal services and meet increasingly stringent demands for
            transparency and service value, and yet there are still frustrations
            with the status quo.
          </p>
          <p className="paragraph">
            This is why we have built Fibonacci: to provide a single source of
            truth on one platform - one that can expose further efficiencies and
            realise the productivity gains offered by legal project management.
          </p>
          <p className="paragraph">
            <b>What is Legal Project Management, and why is it important?</b>
          </p>
          <p className="paragraph">
            Project managers are commonplace in the majority of process-driven
            industries - take a look at construction, finance, software
            development, and healthcare. There are entire service-delivery
            business models committed to the provision of highly-trained project
            managers: goliath professional services firms such as PwC and
            Deloitte promote project management best practices, and their
            consulting teams are deployed to manage workflows, budgets, and
            reports for their stakeholders.
          </p>
          <p className="paragraph">
            Irrespective of their industry, project managers share a common
            goal: to oversee the successful delivery of their projects. On its
            own, that sounds obvious and decidedly unremarkable, but it becomes
            more insightful when you break down a project manager’s role and
            peer into their four distinct areas of responsibility, namely:
          </p>
          <ol>
            <li>Planning</li>
            <li>Resourcing</li>
            <li>Scoping</li>
            <li>Delivery</li>
          </ol>
          <p className="paragraph">
            These could be broken down ad infinitum, into their respective
            sub-responsibilities, but that’s somewhat “out of scope” for now.
          </p>
          <p className="paragraph">
            When it comes to the legal industry, project managers are far less
            common, and yet the principles of project management, fittingly
            dubbed legal project management or LPM, are becoming increasingly
            prevalent and recognised in the sector, despite LPM having become
            something of an industry buzzword.
          </p>
          <p className="paragraph">
            Barclays, for example, has a well-defined and well-adhered to set of
            legal project management principles which are deployed to any matter
            which values over £1 million - and this is a practice that we’ve
            seen used internally at other large corporates and law firms, to
            ensure that projects are delivered on time, within scope, and on
            budget. For large international organisations and their in-house
            corporate legal teams, managing the services provided by their
            external counsel is commonly governed by LPM methodologies, enhanced
            by the implementation of technology point-solutions. The volume of
            work performed by external counsel for a global organisation such as
            Barclays is vast, and the sizeable legal spend is one of the largest
            cost-centers in the group. It follows, then, that relationship and
            engagement activities with their panel law firms should be
            scrutinised and moderated for maximum possible efficiency.
          </p>
          <p className="paragraph">
            The core tenets of LPM are synonymous with all good project
            management principles, and the driving force behind the industry’s
            increasing attention on LPM stems from increased pressure on legal
            teams to provide more value for less - be that less time or budget
            (or frequently, both!) Traditionally, the onus is on the in-house
            corporate team and the general counsel to oversee how their legal
            workload is conducted, and indeed lawyers have been de-facto project
            managers in their own right for as long as there has been a matter
            to manage. The role of the legal project manager is to chart the
            course of a matter in advance: to scope and plan it’s structure,
            ensure that resources are allocated effectively, and to manage its
            lifecycle to completion. Not only does the engagement of a legal
            project manager trend towards increased matter profitability for a
            law firm, but the associated operational improvements and
            coordination help to drive efficiency and better manage legal spend.
          </p>
          <p className="paragraph">
            In a post-COVID 19 landscape, this emphasis on comprehensive
            planning and scoping of matters ahead of time, diligent resource
            allocation, and accurate progress updates is more important than
            ever. Scrutiny over expenditure promises to be more rigorous, and
            corporates will simply not be able to sign-off on excessive legal
            fees to external providers without being able to determine exactly
            what work is being done by lawyers to merit their valuable budget
            allocation.
          </p>
          <p className="paragraph">
            <b>Challenging the status quo</b>
          </p>
          <p className="paragraph">
            How do legal professionals manage projects and matters? How do we
            collaborate, share knowledge, and manage visibility across all the
            parties that may be involved in delivering a matter?
          </p>
          <p className="paragraph">
            The increasing number of legal project managers deployed, both in
            corporates and in law firms, means that the coordination and
            administrative responsibilities of legal projects and matters have
            shifted away from the fee earners, freeing them up to focus their
            time and resources on what they do best.
          </p>
          <p className="paragraph">
            The tools at their disposal, however, remain variable. Law firms use
            an array of different technology solutions and processes, frequently
            at the behest of their clients. This means that communication is
            often inconsistent, collaboration is not truly aligned and best
            practices are not fully formed or maintained.
          </p>
          <p className="paragraph">
            Bird & Bird’s Employment team stressed that receiving and managing
            client materials varies greatly between engagements: in particular
            when using spreadsheets to request information which must then be
            manually tracked and collated. Anyone who has ever spent any
            meaningful time navigating the intricacies of Excel will empathize
            with this point in particular: how often have you received a
            spreadsheet to diligently populate, only to find that it is made up
            of unfamiliar columns, colour codes, and the occasional wonky
            formula? When cross-team collaboration is in full swing, data and
            files are shared back and forth, and there is a real risk that
            information may be erroneously omitted rather than carried over to
            the eponymous “master file” or waylaid amongst the noise of a busy
            inbox.
          </p>
          <p className="paragraph">
            In fact, a recurring piece of feedback that we have gathered, is
            that collaboration efforts are too often quagmired by email traffic
            and inbox volume. This is particularly evident when coordinating on
            matters that span multiple jurisdictions, involving numerous teams,
            and is further exacerbated when third-party organisations are
            involved. No doubt every lawyer or legal professional can recount at
            least one time they were not cc’d into an important response and
            consequently lost visibility over crucial matter progression or
            update.
          </p>
          <p className="paragraph">
            Whilst working with Bird & Bird’s IP litigation team, we saw
            first-hand the thousands of documents that were sent, received,
            logged, and reviewed whilst managing a pan-European patent
            infringement project. There is a well-trodden process of storing and
            sorting documentation in the firm’s document management system,
            responding to correspondence, and sharing marked-up copies and edits
            amongst team members via email - but it is inescapably manual and
            laborious.
          </p>
          <p className="paragraph">
            These manual processes are spread between multiple tools from
            different providers - tools that aren’t always built to work
            together, and the onus is on the user to jury-rig a process that
            works best on an ad-hoc basis, according to client demands or team
            requirements. Fundamentally, both in-house lawyers and their
            in-practice counterparts still spend too much time collating data
            and coordinating workstreams, whilst managing a deluge of emails and
            documents. In turn, this directly competes with the time available
            for their substantive legal work and strategy, which is required to
            deliver results for the very matters that they are managing.
          </p>
          <p className="paragraph">
            <b>
              Legal project management technology empowers legal project
              managers
            </b>
          </p>
          <p className="paragraph">
            We have worked hand in glove with a number of prominent law firms
            and corporate legal teams, in order to discover and understand
            exactly what it is that drives successful LPM practices in their
            organisations and, crucially, where the inefficiencies remain.
            Thanks to the time and efforts of the members of our steering group,
            we’ve been able to study distinct workflows and commonalities across
            practice areas, and build a comprehensive platform that benefits
            both client and practitioner.
          </p>
          <p className="paragraph">
            Having spoken about how Barclays manages their LPM processes with
            Helga Butcher, the bank’s lead project portfolio manager, it is
            clear that technology plays an important role in empowering legal
            project management professionals to best execute their function.
            From the point of view of an in-house professional, Helga stressed
            that the importance of adopting new technology is an exercise in
            relationship-building.
          </p>
          <TestimonialSlider>
            <Quote
              classes="quote-responsive"
              author="Helga Butcher"
              position="Director, Legal Project Management"
              avatar={helgaButcher}
              logo={ventures}
              content="Having the right legal service delivery technology to encourage and underpin
              that collaboration piece between corporates and the law firms that service them is absolutely key."
            />
          </TestimonialSlider>

          <p className="paragraph">
            Communication and collaboration are two of Fibonacci’s founding
            principles. Not only is internal communication crucial for the team
            delivering a matter, but clients are increasingly demanding more
            transparency and visibility over how their matters are managed.
          </p>
          <p className="paragraph">
            Fibonacci enables full transparency over all the workstreams
            involved in the delivery of a matter, enabling participants to
            remain on the front foot - to see what has been performed and what
            tasks are upcoming. This ethos applies to all elements of an
            engagement: as we gathered from the Barclays team, costs are a
            primary concern when engaging external counsel. Fibonacci’s
            championing of visibility extends to matter financials and by way of
            a built-for-purpose financial tracker, you’ll be able to track
            project spend alongside the agreed estimates, to minimise the risk
            of cost blowouts.
          </p>

          <TestimonialSlider>
            <Quote
              classes="quote-responsive"
              author="Helga Butcher"
              position="Director, Legal Project Management"
              avatar={helgaButcher}
              logo={ventures}
              content="Clients want to know what law firms are doing for their money - LPMs help to
              shed a light on that and, crucially, LPM technology helps them do so."
            />
          </TestimonialSlider>

          <p className="paragraph">
            <b>Why Fibonacci</b>
          </p>
          <p className="paragraph">
            Fibonacci is a true built-for-purpose legal project management
            platform. Rather than pivoting existing software to meet some of the
            demands of the sector, we have specifically designed, iterated, and
            tested each functionality with the help of our steering committee.
          </p>
          <p className="paragraph">
            Working in partnership with firms such as Bird & Bird, Paul
            Hastings, Ashurst, Addleshaw Goddard, Simmons & Simmons, as well as
            the LPM and M&A divisions of Barclays’ corporate legal team, we have
            identified the need for collaboration features, workflow automation
            tools, and reporting mechanics: fundamental tools, no matter the
            practice area.
          </p>
          <p className="paragraph">
            We recognise that there’s no ‘one size fits all’ solution, and so
            Fibonacci is modular by design. Whether for complex
            multi-jurisdictional litigation, high-stakes, and fast-moving M&A
            transactions, or cross-border employment restructuring, Fibonacci
            can be assembled to suit various workflows. In tandem with this
            building-blocks approach, Fibonacci is designed with an open-access
            philosophy in mind - to work alongside your existing tools from
            other vendors, as well as leaving open the door to future
            technologies and synergies.
          </p>
          <p className="paragraph">
            It has been a long and rewarding road to reach this point. Each
            build of Fibonacci has been tested and iterated upon, utilising
            expert feedback from our ambitious steering committee. That’s not to
            say we’re done learning, far from it: we will leave no stone
            unturned in ensuring that Fibonacci truly revolutionises legal
            service delivery.
          </p>
          <p className="paragraph">
            <i>Interested to see how Fibonacci can transform your legal operations?</i>
          </p>
          <div className="author-details">
            <div className="label _bold">Author</div>
            <div className="base-chip -large author">
              <img className="avatar -no-border" src={lukeEvans} alt="" />
              <div className="text">
                <div className="title">
                  <span className="name">Luke Edwardes-Evans</span>
                </div>
                <div className="subtitle">Product Success Manager, Fibonacci</div>
                <img className="logo -custom" src={fibonacci} alt="" />
              </div>
            </div>
            <a
              className="base-button -inline -message message-link"
              href="mailto:luke@fibonacci.legal"
            >
              Message Luke
            </a>
          </div>
        </div>
        <div className="social-container social">
          <div className="header">Like this article? Spread the word</div>
          <div className="logos">
            <LinkedinShareButton
              title="Fibonacci: the Golden Standard for Legal Project Management"
              url="https://fibonacci.legal/blog/fibonacci-the-golden-standard-for-legal-project-management"
            >
              <div className="social-button">
                <img src={linkedin} alt="" />
              </div>
            </LinkedinShareButton>
            <TwitterShareButton
              title="Fibonacci: the Golden Standard for Legal Project Management"
              url="https://fibonacci.legal/blog/fibonacci-the-golden-standard-for-legal-project-management"
            >
              <div className="social-button">
                <img src={twitter} alt="" />
              </div>
            </TwitterShareButton>
            <FacebookShareButton
              quote="Fibonacci: the Golden Standard for Legal Project Management"
              url="https://fibonacci.legal/blog/fibonacci-the-golden-standard-for-legal-project-management"
            >
              <div className="social-button">
                <img src={facebook} alt="" />
              </div>
            </FacebookShareButton>
          </div>
        </div>
        <h2 className="_bold divider">Keep reading</h2>
        <div className="card-gallery gallery">
          <Card
            className="heading"
            to="/blog/transforming-multi-jurisdictional-litigation-with-bird-and-bird"
            image={buildings}
            title="Transforming multi-jurisdictional litigation with Bird & Bird"
            author="Raphael Cardona"
          />
          <Card
            image={helgaButcherArticle}
            imageStyle={{ objectPosition: 'top' }}
            title="From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays"
            to="/blog/from-the-legal-project-managers-mouth"
            author="Luke Edwardes-Evans"
          />
        </div>
      </div>

      <div className="topbg" />
      <div className="bottombg" />
    </div>
  </Layout>
);
